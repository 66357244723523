@import url("https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:600&display=swap");

.nav-link {
  color: white !important;
}

.nav-link :hover {
  color: rgb(240, 179, 66) !important;
}

.brand {
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 3vh;
  text-transform: uppercase;
}

#mainNav {
  background: #000428; /* fallback for old browsers */
  background: -webkit-linear-gradient(-45deg, #170a2a, #f8b231 95%) !important;
  background: linear-gradient(-45deg, #170a2a, #2d567c 95%) !important;
}
